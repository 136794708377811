import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../service/Service';

const MBWay = ({ value, formReplies, fixedValue, itemPurchases }) => {
  const [alias, setAlias] = useState('');
  const [error, setError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const [result, setResult] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSubmitting) {
      await handleFetch(); // Only submit if not already submitting
    }
  };

  const handleFetch = async () => {
    setIsSubmitting(true); // Disable button when fetch starts
    try {
      const body = {
        value,
        alias,
        email,
        fixedValue,
        itemPurchases,
        formID: formReplies.formID,
        replyFields: formReplies.replyFields,
      };

      const options = {
        method: 'POST',
        headers: { accept: 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(body),
      };

      const response = await fetch(`${URL}/api/payment/mbway`, options);
      const result = await response.text();

      // If successful, redirect to success page
      if (response.ok) {
        const parsedResult = JSON.parse(result);
        setResult(parsedResult);

        navigate('/clientforms/success/mbw', { state: { paymentinfo: parsedResult } });
      } else {
        setError('Ocorreu um erro. Por favor tente novamente');
      }
    } catch (error) {
      setError('Ocorreu um erro. Por favor tente novamente');
    } finally {
      setIsSubmitting(false); // Re-enable button when fetch completes
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="mt-5">
      <label className="block mb-2 text-sm font-bold text-gray-900">Email</label>
      <input
        type="email"
        name="email"
        placeholder="Insira o seu Email"
        required
        className="border mb-5 border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        onChange={(event) => setEmail(event.target.value)}
      />
      <label className="block mb-2 text-sm font-bold text-gray-900">Número de Telefone</label>
      <input
        type="tel"
        name="phone"
        placeholder="9XXXXXXXX"
        required
        className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        onChange={(event) => setAlias(event.target.value)}
      />
      <small>* Apenas números portugueses</small>

      {error && (
        <div className="bg-red-100 mt-2 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Erro! </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="mt-10 text-gray-900 text-sm rounded focus:ring-blue-500 mb-5 focus:border-blue-500 inline-flex w-full p-2.5">
        <input
          id="verify-checkbox"
          type="checkbox"
          className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          required
          onChange={handleCheckboxChange}
        />
        <label htmlFor="verify-checkbox" className="cursor-pointer">
          Tomei conhecimento que a informação constante deste formulário é guardada e tratada pela Eupago e dou consentimento para tal.
        </label>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          disabled={!isChecked || isSubmitting} // Disable if checkbox not checked or submitting
          className={`ml-3 w-full sm:w-auto bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded ${(!isChecked || isSubmitting) ? 'cursor-not-allowed opacity-50' : ''
            }`}
          type="submit"
        >
          {isSubmitting ? 'A Processar...' : 'Finalizar'} {/* Change text while submitting */}
        </button>
      </div>
    </div>
  );
};

export default MBWay;
