import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import Context from '../../context/Context';
import { fetchForm, editForm, fetchFormElements, editFormElement } from '../../context/forms/FormsAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL, URL_FRONT } from "../../service/Service";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { jwtDecode } from 'jwt-decode';
import {QuestionMarkCircleIcon} from "@heroicons/react/20/solid";
import {ExclamationTriangleIcon } from "@heroicons/react/24/outline";



const options = ["", "Cartão de Crédito", "Multibanco", "Mb Way", "Numerário"];


const paymentImages = {
    "Cartão de Crédito": "https://eupago.pt/images/tarifario-meios-12.webp",
    "Multibanco": "https://eupago.pt/images/tarifario-meios-08.webp",
    "Mb Way": "https://eupago.pt/images/tarifario-meios-01.webp",
    "Numerário" : "",
}


const FormWizard2 = () => {
    const { state, dispatch } = useContext(Context);
    const { form } = state;
    const { idform } = useParams();
    const [name, setName] = useState(form.data.name);
    const [info, setInfo] = useState(form.data.info)
    const [latitude, setLatitude] = useState(form.data.latitude);
    const [longitude, setLongitude] = useState(form.data.longitude);
    const [apiKey, setApiKey] = useState(form.data.apiKey);
	const [showError, setShowError] = useState(false);
	const [errorMessages, setErrorMessages] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isFormValid, setIsFormValid] = useState(false);
    const token = sessionStorage.getItem('jwt');
    const userRole = jwtDecode(token).authorities[0].authority;
    const location = useLocation();
    const navigate = useNavigate()
    const [stock, setStock] = useState(form.data.stock);
    const [stockOption, setStockOption] = useState(form.data.stockOption);
    const [maxQuantityPerElement, setMaxQuantityPerElement] = useState(form.data.maxQuantityPerElement);
    const [maxQuantityOption, setMaxQuantityOption] = useState(form.data.maxQuantityOption);
    const [stockDecrementOption, setStockDecrementOption] = useState('PENDING');
    const [formElements, setFormElements] = useState();
    const { data } = state.formelements;
    const [quantities, setQuantities] = useState({});
    const [maxQuantities, setMaxQuantities] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const isAdminPath = location.pathname.startsWith('/admin/forms/');
        const isFormWizard1Path = location.pathname.endsWith('/formwizard/2');

        if (isAdminPath && isFormWizard1Path && userRole !== 'ADMIN') {
            navigate('/login'); // Redirect to login screen if not ADMIN and accessing admin path
        }
    }, [location.pathname, navigate, userRole]);

    useEffect(() => {
        const url = `${URL}/api/forms/${idform}/formelements`;
        const url_form = `${URL}/api/forms/${idform}`;
        async function fetchData() {
            await fetchFormElements(url, {}, dispatch);
            await fetchForm(url_form, {}, dispatch);
            setFormElements(Object.values(data));
        }
        fetchData();
    }, [dispatch, idform]);

    useEffect(() => {
        if (Array.isArray(formElements) && formElements.length > 0) {
            const initialQuantities = formElements
                .filter(element => element.element_type === 'price')
                .reduce((acc, element) => {
                    acc[element.id] = element.stock || 0; // Set the initial quantity to the element's stock
                    return acc;
                }, {});
            setQuantities(initialQuantities);
        }
    }, [formElements])

    useEffect(() => {
        if (Array.isArray(formElements) && formElements.length > 0) {
            const initialMaxQuantities = formElements
                .filter(element => element.element_type === 'price')
                .reduce((acc, element) => {
                    acc[element.id] = element.maxQuantityPerElement || 0; // Set the initial quantity to the element's stock
                    return acc;
                }, {});
            setMaxQuantities(initialMaxQuantities);
        }
    }, [formElements]);

    useEffect(() => {
        setFormElements(Object.values(data));
    }, [data]);

    useEffect(() => {
        setPaymentOptions(form.data?.paymentOptions ?? []);
    }, [form.data?.paymentOptions]);

    useEffect(() => {
        if (name !== "" && apiKey !== "" && paymentOptions.length > 0) {
            setIsFormValid(true)
        }
    }, [name, apiKey, paymentOptions]);


    useEffect(() => {
        setStockOption(form.data?.stockOption ?? '');
        setStock(form.data?.stock ?? '')
        setStockDecrementOption(form.data?.stockDecrementOption ?? 'PENDING');
    }, [form.data?.stockOption,form.data?.stock, form.data?.stockDecrementOption]);

    useEffect(() => {
        setMaxQuantityOption(form.data?.maxQuantityOption ?? '');
        setMaxQuantityPerElement(form.data?.maxQuantityPerElement ?? '');
    }, [form.data?.maxQuantityOption, form.data?.maxQuantityPerElement]);

    const updateHandler = async () => {
        const url = `${URL}/api/forms/${idform}`;
        const request = {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                name: name,
                paymentOptions: paymentOptions,
                apiKey: apiKey,
                info: info,
                latitude: latitude,
                longitude: longitude,
                stock: stock,
                stockOption: stockOption,
                stockDecrementOption: stockDecrementOption,
                maxQuantityPerElement: maxQuantityPerElement,
                maxQuantityOption: maxQuantityOption
            }),
        };
        try {
            await editForm(url, request, dispatch);
        } catch (error) {
            throw error;
        }
    };

    const updateFormElement = async (formElementId, updateFields) => {
        const url = `${URL}/api/forms/${idform}/formelements/${formElementId}`;
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                ...updateFields,
                maxQuantityOption: maxQuantityOption,
                stockOption: stockOption
            }),
        };
        try {
            await editFormElement(url, request, dispatch);
        } catch (error) {
            throw error;
        }
    };


    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            setErrorMessages([]);
            setShowError(false);
            setShowModal(true);

            try {
            await updateHandler(); // Update the form details

            if (stockOption === 'TYPE_LIMITED') {
                formElements
                    .filter(element => element.element_type === 'price')
                    .forEach(element => {
                        const stockValue = quantities[element.id];
                        updateFormElement(element.id, { stock: stockValue });
                    });
            }

            if (maxQuantityOption === 'TYPE_LIMITED') {
                formElements
                    .filter(element => element.element_type === 'price')
                    .forEach(element => {
                        const maxQuantityPerElementValue = maxQuantities[element.id];
                    updateFormElement(element.id, { maxQuantityPerElement: maxQuantityPerElementValue });
                    });
            }


            setTimeout(() => {
                if (userRole === 'ADMIN') {
                    window.location.href = `${URL_FRONT}/admin/forms/`;
                } else {
                    window.location.href = `${URL_FRONT}/forms/`;
                }
            }, 2000);

            /*// Navigate to the next step
            if (userRole === 'ADMIN') {
                window.location.href = `${URL_FRONT}/admin/forms/${idform}/formwizard/3`;
            } else {
                window.location.href = `${URL_FRONT}/forms/${idform}/formwizard/3`;
            }*/
            } catch (error) {
                console.error("Error during form update: ", error);

                let backendErrorMessage =
                    'Ocorreu um erro ao atualizar o formulário. Por favor, tente novamente mais tarde.';
                if (error.message) {
                    backendErrorMessage = error.message;
                } else if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    backendErrorMessage = error.response.data.message;
                }

                setErrorMessages([backendErrorMessage]);
                setShowError(true);
            }
        },
        [updateHandler, stockOption, maxQuantityOption, formElements, quantities, maxQuantities, idform, userRole]
    );

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
    }, []);

    const handleInfoChange = useCallback((event) => {
        setInfo(event.target.value);
    }, []);

    const handleApiKeyChange = useCallback((event) => {
        setApiKey(event.target.value);
    }, []);

    const handleLatitudeChange = useCallback((event) => {
        setLatitude(event.target.value);
    }, []);

    const handleLongitudeChange = useCallback((event) => {
        setLongitude(event.target.value);
    }, []);


    const handleOptionChange = useCallback((event) => {
        setSelectedOption(event.target.value);
    }, []);

    const handleAddOption = () => {
        if (selectedOption && !paymentOptions.includes(selectedOption)) {
            setPaymentOptions([...paymentOptions, selectedOption]);
            setSelectedOption("");
        }
    };

    const handleDeleteOption = (optionToDelete) => {
        const newOptions = paymentOptions.filter((option) => option !== optionToDelete);
        setPaymentOptions(newOptions);
    }

    const handleButtonClickBack = () => {
        if (userRole === 'ADMIN') {
            navigate(`/admin/forms/${idform}/formwizard/1`);
        } else {
            navigate(`/forms/${idform}/formwizard/1`);
        }
    };

    const handleStockChange = (event) => {
        setStock(event.target.value);
    };


    const handleStockOptionChange = useCallback((event) => {
        setStockOption(event.target.value);
    }, []);

    const handleStockDecrementOptionChange = useCallback((event) => {
        setStockDecrementOption(event.target.value);
    }, []);


    const handleMaxQuantityPerElementChange = (event) => {
        let value = parseInt(event.target.value, 10);
        if (isNaN(value)) value = 0;
        if (value > 9999) {
            value = 9999;
            setWarningMessage('O valor máximo permitido é 9999.');
        } else {
            setWarningMessage('');
        }
        setMaxQuantityPerElement(value);
    };

    const handleMaxQuantityOptionChange = useCallback((event) => {
        setMaxQuantityOption(event.target.value);
    }, []);


    const handleQuantityChange = (id, event) => {
        const value = event.target.value;
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [id]: value,
        }));
    };

    const handleMaxQuantityChange = (id, event) => {
        let value = parseInt(event.target.value, 10);
        if (isNaN(value)) value = 0;
        if (value > 9999) {
            value = 9999;
            setWarningMessage('O valor máximo permitido é 9999.');
        } else {
            setWarningMessage('');
        }
        setMaxQuantities((prevMaxQuantities) => ({
            ...prevMaxQuantities,
            [id]: value,
        }));
    };

    return (
        <div className="p-5">
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="flex justify-between items-center mb-10">
                        <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                                onClick={handleButtonClickBack}

                                type="button"
                        >Anterior</button>
                        <div className='w-full ml-10 mr-10 mt-8'>
                            <div className="overflow-hidden rounded-full bg-gray-200">
                                <div className="h-2  rounded-full bg-green-cl"></div>
                            </div>

                            <ol className="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
                                <li className="flex items-center justify-start text-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                    </svg>

                                    <span className="hidden sm:inline font-bold"> Editor </span>

                                </li>
                                <li className="flex items-center justify-center text-black font-bold">
                                </li>

                                    <li className="flex items-center justify-end text-black font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                        </svg>
                                        <span className="hidden sm:inline"> Definições </span>
                                    </li>
                                    {/*<li className="flex items-center justify-end text-black">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                    </svg>

                                    <span className="hidden sm:inline"> Upload Imagem </span>
                                </li>*/}
                            </ol>
                        </div>
                        <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                                type="submit"
                        >Finalizar</button>
                    </div>
                    {showModal && (
                        <div className="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
                            <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
                                <button className="absolute top-0 right-0 px-2 py-1" type='button'>x</button>
                                <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                    <div className="text-green-500">
                                        <svg className="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </div>
                                    <div className="text-sm font-medium ml-3">Sucesso:</div>
                                </div>
                                <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">O seu formulário foi guardado com sucesso</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-2 bg-white p-6 rounded border shadow-md">
                    <div className="mb-4">
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Nome do
                            Formulário:</label>
                        <input
                            className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.name}
                            onChange={handleNameChange}
                            required
                        />
                    </div>
                    <div className='mb-8'></div>
                    <div className="mb-4">
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Chave API:</label>
                        <input
                            className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.apiKey}
                            onChange={handleApiKeyChange}
                            required
                        />
                    </div>
                    <div className='mb-8'></div>
                    <div className="mb-5">
                        <label htmlFor="stockOption" className="block font-bold text-gray-900">
                            Limitação de Stock:
                        </label>
                        <select
                            id="stockOption"
                            name="stockOption"
                            value={stockOption}
                            onChange={handleStockOptionChange}
                            onWheel={() => document.activeElement.blur()}
                            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        >
                            <option value="UNLIMITED">Ilimitado</option>
                            <option value="LIMITED">Limitado</option>
                            <option value="TYPE_LIMITED">Limitado por Tipo</option>
                        </select>

                        <div className='mb-8'></div>

                        {stockOption !== 'UNLIMITED' && (
                            <div className="mb-5">
                                <label htmlFor="stockDecrementOption" className="block font-bold text-gray-900 flex">
                                    Reduzir stock disponível quando a compra estiver no estado:
                                    <a
                                        href="#"
                                        title="Se a escolha for 'pago', o stock só será reduzido quando a compra estiver paga.
Se a escolha for 'pendente', o stock será reduzido quando a compra for realizada."
                                        className="ml-2"
                                    >
                                        <QuestionMarkCircleIcon className="w-5 h-5 text-gray-500"/>
                                    </a>
                                </label>
                                <select
                                    id="stockDecrementOption"
                                    name="stockDecrementOption"
                                    value={stockDecrementOption}
                                    onChange={handleStockDecrementOptionChange}
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                                >
                                    <option value="PENDING">Pendente</option>
                                    <option value="PAID">Pago</option>
                                </select>
                            </div>
                        )}


                        <div className='mb-8'></div>
                        {stockOption === 'LIMITED' && (
                            <div className="mt-3 flex flex-col md:flex-row items-start md:items-center ml-0 md:ml-6">
                                <div className="flex-grow relative w-full">
                                    <span
                                        className="absolute top-1/2 p-2 -translate-y-1/2 text-white bg-green-cl rounded-l">
                                        Stock Disponível
                                    </span>
                                    <input
                                        type="number"
                                        id="stock"
                                        name="stock"
                                        value={stock}
                                        onChange={handleStockChange}
                                        onWheel={() => document.activeElement.blur()}
                                        className="p-2 pl-[150px] border border-gray-300 rounded-md w-full"
                                        min="0"
                                    />
                                </div>
                            </div>
                        )}

                        {stockOption === 'TYPE_LIMITED' && Array.isArray(formElements) && formElements
                            .filter(element => element.element_type === 'price')
                            .map((element) => (
                                <div key={element.id}
                                     className="mt-3 flex flex-col md:flex-row items-start md:items-center ml-0 md:ml-6">
                                    <label
                                        htmlFor={`quantity-${element.id}`}
                                        className="block font-bold text-gray-900 flex-shrink-0 w-full md:w-auto min-w-[150px] mb-2 md:mb-0">
                                        {element.label}
                                    </label>
                                    <div className="flex-grow relative w-full">
                                        <span
                                            className="absolute top-1/2 p-2 -translate-y-1/2 text-white bg-green-cl rounded-l">
                                            Stock Disponível
                                        </span>
                                        <input
                                            type="number"
                                            id={`quantity-${element.id}`}
                                            name={`quantity-${element.id}`}
                                            value={quantities[element.id] ?? element.stock}
                                            onChange={(e) => handleQuantityChange(element.id, e)}
                                            onWheel={() => document.activeElement.blur()}
                                            className="p-2 pl-[150px] border border-gray-300 rounded-md w-full"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className='mb-8'></div>
                    <div className="mb-5">
                        <label htmlFor="maxQuantityOption" className="block font-bold text-gray-900 flex">
                            Quantidade disponível por resposta de formulário:
                            <a
                                href="#"
                                title="Limita o número máximo adquirivel por formulário. (Recomenda-se que seja um limite inferior ao stock disponível)"
                                className="ml-2"
                            >
                                <QuestionMarkCircleIcon className="w-5 h-5 text-gray-500"/>
                            </a>
                        </label>
                        <select
                            id="maxQuantityOption"
                            name="maxQuantityOption"
                            value={maxQuantityOption}
                            onChange={handleMaxQuantityOptionChange}
                            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                        >
                            <option value="UNLIMITED">Ilimitado</option>
                            <option value="LIMITED">Limitado</option>
                            <option value="TYPE_LIMITED">Limitado por Tipo</option>
                        </select>


                        <div className='mb-8'></div>
                        {maxQuantityOption === 'LIMITED' && (
                            <div className="mt-3 flex flex-col md:flex-row items-start md:items-center ml-0 md:ml-6">
                                <div className="flex-grow relative w-full">
                                    <span
                                        className="absolute top-1/2 p-2 -translate-y-1/2 text-white bg-green-cl rounded-l">
                                        Quantidade Máxima
                                    </span>
                                    <input
                                        type="number"
                                        id="maxQuantityPerElement"
                                        name="maxQuantityPerElement"
                                        value={maxQuantityPerElement}
                                        onChange={handleMaxQuantityPerElementChange}
                                        onWheel={() => document.activeElement.blur()}
                                        className="p-2 pl-[175px] border border-gray-300 rounded-md w-full"
                                        min="0"
                                    />
                                </div>
                            </div>
                        )}

                        {maxQuantityOption === 'TYPE_LIMITED' && Array.isArray(formElements) && formElements
                            .filter(element => element.element_type === 'price')
                            .map((element) => (
                                <div key={element.id}
                                     className="mt-3 flex flex-col md:flex-row items-start md:items-center ml-0 md:ml-6">
                                    <label
                                        htmlFor={`maxQuantity-${element.id}`}
                                        className="block font-bold text-gray-900 flex-shrink-0 w-full md:w-auto min-w-[150px] mb-2 md:mb-0">
                                        {element.label}
                                    </label>
                                    <div className="flex-grow relative w-full">
                                        <span
                                            className="absolute top-1/2 p-2 -translate-y-1/2 text-white bg-green-cl rounded-l">
                                            Quantidade Máxima
                                        </span>
                                        <input
                                            type="number"
                                            id={`maxQuantity-${element.id}`}
                                            name={`maxQuantity-${element.id}`}
                                            value={maxQuantities[element.id] ?? element.maxQuantityPerElement}
                                            onChange={(e) => handleMaxQuantityChange(element.id, e)}
                                            onWheel={() => document.activeElement.blur()}
                                            className="p-2 pl-[175px] border border-gray-300 rounded-md w-full"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>


                    {/* <div>
                        <label htmlFor="default-input" className="block font-bold text-gray-900">Informação do Formulário:</label>
                        <textarea
                            className="max-h-64 h-20 w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            type="text"
                            defaultValue={form.data.info}
                            onChange={handleInfoChange}
                        />
                        <small>Aceita tags HTML como <b>{'<b>'}</b> texto exemplo<b>{'</b>'}</b> para negrito e <b>{'<p>'}</b> texto exemplo<b>{'</p>'}</b> para parágrafo.</small>
                        <div className='mb-8'></div>
                    </div>
                    <div className="flex flex-col md:flex-row ">
                        <div className="w-full md:w-1/2 md:mr-2">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">Latitude:</label>
                            <input
                                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                type="text"
                                defaultValue={form.data.latitude}
                                onChange={handleLatitudeChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 md:ml-2 mt-4 md:mt-0">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">Longitude:</label>
                            <input
                                className="w-full border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                type="text"
                                defaultValue={form.data.longitude}
                                onChange={handleLongitudeChange}
                            />
                        </div>
                    </div>
                    <small className='mb-4'>Adicione as coordenadas do seu Evento para visualizar o mapa do local, mantenha os valores a 0 para não mostrar o mapa.</small>
                    <div className='mb-8'></div> */}
                    <div>
                        <div className="mb-4">
                            <label htmlFor="default-input" className="block font-bold text-gray-900">
                                Adicionar Métodos de Pagamento:
                            </label>
                            <div className='flex mt-2'>
                                <select
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                    className="mr-2 w-full bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                >
                                    {options.map((option) => (
                                        <option key={option} value={option}>
                                            {option || "Escolha um Método"}
                                        </option>
                                    ))}
                                </select>
                                <button type="button"
                                        className="w-full sm:w-auto sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white h-10 px-4 rounded"
                                        onClick={handleAddOption}>Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='mb-8'></div>
                    {paymentOptions.length > 0 && (
                        <label htmlFor="default-input" className="block font-bold text-gray-900">
                            Métodos Ativos:
                        </label>
                    )}
                    <div className='mb-5'></div>
                    <div className="flex flex-wrap">
                        {paymentOptions.map((option) => (
                            <div
                                key={option}
                                className="flex items-center mb-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-2 relative"
                            >
                                <div
                                    className="bg-white p-6 rounded border items-center justify-center text-center shadow-md overflow-hidden w-full hover:shadow-lg transition duration-300 ease-in-out">
                                    <div className="flex justify-center">
                                        <img
                                            src={paymentImages[option]}
                                            className="h-10 mb-4"
                                        />
                                    </div>
                                    <div className="mb-4 font-bold">{option}</div>
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteOption(option)}
                                        className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                                    >
                                        <DeleteForeverIcon/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {showError && errorMessages.length > 0 && (
                        <div className="fixed bottom-0 right-0 mb-4 mr-4">
                            <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                <button
                                    className="absolute top-0 right-0 px-2 py-1"
                                    type="button"
                                    onClick={() => setShowError(false)}
                                >
                                    x
                                </button>
                                <div className="flex flex-row items-center w-full sm:w-auto">
                                    <div className="text-orange-500">
                                        SVG Icon
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="orange"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="text-sm text-gray-600 ml-4">
                                        {errorMessages.map((msg, index) => (
                                            <div key={index}>{msg}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {warningMessage && (
                        <div className="fixed bottom-0 right-0 mb-4 mr-4">
                            <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center border border-red-500 bg-red-100 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                <button
                                    className="absolute top-0 right-0 px-2 py-1 text-red-700"
                                    type="button"
                                    onClick={() => setWarningMessage('')}
                                >
                                    x
                                </button>
                                <div className="flex flex-row items-center w-full sm:w-auto">
                                    <ExclamationTriangleIcon className="w-6 h-6 text-red-500" />
                                    <div className="text-sm text-red-700 ml-4">
                                        {warningMessage}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

  </form>
</div>
);}

export default FormWizard2;