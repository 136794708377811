import React, { useState, useEffect } from 'react';
import euforms from "../../images/euforms.png";

const MBWay = ({paymentinfo}) => {
  const [timeLeft, setTimeLeft] = useState(240); // 4 minutes in seconds

  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className='overflow-x-hidden'>
            <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-8" src={euforms} alt="Logo" />
                </div>
            </nav>

      <div className="mt-10 flex flex-col items-center justify-center bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-md rounded-lg p-6 sm:p-12 w-full max-w-4xl">
          <h1 className="text-2xl sm:text-4xl font-bold text-green-600 text-center mb-6 sm:mb-10">
            O seu formulário foi preenchido com sucesso!
          </h1>
          <div className="flex justify-center mb-6">
            <img
              className="w-24 sm:w-32"
              src="https://eupago.pt/images/tarifario-meios-01.webp"
              alt="Multibanco"
            />
          </div>
          <p className="text-base sm:text-lg text-gray-700 text-center mb-6 sm:mb-10">
          Dispõe agora de 4 minutos para aceitar o pagamento no <b>valor de {parseFloat(paymentinfo.value).toFixed(2).replace(/\./g, ',')}€</b> na sua aplicação MBWay
          </p>

          <div className="flex justify-center mt-4 sm:mt-8">
            <div className="text-4xl sm:text-6xl font-bold text-green-600">
              {formatTime(timeLeft)}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default MBWay;
