import React from 'react';
import CC from './CC';
import MBWay from './MBWay';
import Multibanco from './Multibanco';
import CashPayment from './CashPayment';


const Method = ({ method, value, formReplies, fixedValue, itemPurchases }) => {

    switch (method) {
        case 'Multibanco':
            return (
                <Multibanco
                    value={value}
                    formReplies={formReplies}
                    fixedValue={fixedValue}
                    itemPurchases={itemPurchases}
                />

            )

        case 'Cartão de Crédito':
            return (
                <CC
                    value={value}
                    formReplies={formReplies}
                    fixedValue={fixedValue}
                    itemPurchases={itemPurchases}
                />
            )

        case 'Mb Way':
            return (
                <MBWay
                    value={value}
                    formReplies={formReplies}
                    fixedValue={fixedValue}
                    itemPurchases={itemPurchases}
                />
            )

        case 'Numerário':
            return (
                <CashPayment
                    value={value}
                    formReplies={formReplies}
                    fixedValue={fixedValue}
                    itemPurchases={itemPurchases}
                />
            )
        default:
            return null;
    }
}

export default Method