import React from 'react'

import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Multibanco from '../../components/successPage/Multibanco';
import CC from '../../components/successPage/CC';
import MBWay from '../../components/successPage/MBWay';
import CashPayment from '../../components/successPage/CashPayment';

const FormSuccess = () => {
    const location = useLocation();
    const { paymentinfo } = location.state || {};
    const { method } = useParams();


    switch (method) {
        case 'mb':
            return (
                <Multibanco
                    paymentinfo={paymentinfo}
                />
            )
        case 'cc':
            return (
                <CC
                />
            )

        case 'mbw':
            return (
                <MBWay
                    paymentinfo={paymentinfo}
                />
            )
        case 'cashpayment':            
        return (
            <CashPayment
                paymentinfo={paymentinfo}
            />
        )
        default:
            return null;
    }


}

export default FormSuccess