import React, { useContext, useState, useEffect } from 'react'
import Context from '../../context/Context';
import { URL, URL_FRONT } from "../../service/Service";
import { useParams } from 'react-router-dom';
import { editFormElement } from '../../context/forms/FormsAction';
import Header from '../TitleAndSubtitle';


const TextblockEditWizard = ({ elementInfo, userRole }) => {
    const { idform, idformelement } = useParams()
    const { dispatch } = useContext(Context);
    const [label, setLabel] = useState(elementInfo.label);
    const [placeholder, setPlaceholder] = useState(elementInfo.placeholder);

    useEffect(() => {
        setPlaceholder(elementInfo.placeholder);
        setLabel(elementInfo.label)
    }, [elementInfo.placeholder, elementInfo.label]);


    const [success, setSuccess] = useState(false); // new state variable

    const updateHandler = async () => {
        const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ placeholder, label }),
        };
    
        const response = await fetch(url, request);
        if (response.status === 200) {
            setSuccess(true);
        }
    
        editFormElement(url, request, dispatch);
    };    


    const handleSubmit = (event) => {
        event.preventDefault();
        updateHandler();
        setTimeout(() => {
            if (userRole === 'ADMIN') {
                window.location.href = `${URL_FRONT}/admin/forms/${idform}/formwizard/1`;
            } else {
                window.location.href = `${URL_FRONT}/forms/${idform}/formwizard/1`;
            }
        }, 1000); // 1000 milliseconds = 1 second
    };
    
    return (
        <div className='p-5'>
        <div className='mb-5'></div>
        <div className="flex flex-col md:flex-row justify-between items-center">
            <Header title="Editar Elemento" />
        </div>

            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label for="default-input" className="p-2.5 ext-sm font-bold text-gray-900">Legenda</label>
                    <input
                        className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text"
                        onChange={e => setLabel(e.target.value)}
                        value={label}
                    />
                </div>
                <div className="mb-4">
                    <label for="default-input" className="p-2.5 ext-sm font-bold text-gray-900">Texto</label>

                    <textarea
                        className="max-h-64 mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text"
                        style={{minHeight: "100px", height: "auto"}}
                        onChange={e => setPlaceholder(e.target.value)}
                        value={placeholder}
                    />
                    <small className="ml-3 text-sm text-gray-600">Máximo de 2000 caracteres</small>
                </div>
                <div className="flex justify-end">
                    <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                    Guardar
                    </button>

                </div>
            </form>
            {success && (
                    <div className="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
                        <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
                            <button className="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setSuccess(false)}>x</button>
                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                <div className="text-green-500">
                                    <svg className="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </div>
                                <div className="text-sm font-medium ml-3">Sucesso:</div>
                            </div>
                            <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Dados guardados com sucesso!</div>
                        </div>
                    </div>
                )}
        </div>
    );
}

export default TextblockEditWizard