import React from 'react'
import euforms from "../../images/euforms.png"

const CC = () => {
  return (
    <div className='overflow-x-hidden'>
      <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <img className="h-8" src={euforms} alt="Logo" />
        </div>
      </nav>

      <div className="mt-20 flex flex-col items-center justify-center bg-gray-100">
        <div className="bg-white shadow-md rounded-lg p-12 w-full max-w-4xl">
          <h1 className="text-4xl font-bold text-green-600 text-center mb-10">
            O seu pagamento foi processado com sucesso!
          </h1>
          
          <div className="flex justify-center mt-8">
            <div className="relative">
              {/* Animated Circle */}
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-32 w-32 text-green-600 opacity-0 animate-checkmark"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
                strokeWidth={2}
              >
                <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
              </svg>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="absolute top-0 left-0 h-32 w-32 text-green-600 opacity-0 animate-checkmark"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CC
