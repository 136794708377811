import React from 'react'
import euforms from "../../images/euforms.png"

const CashPayment = ({ paymentinfo }) => {


    const handleCopy = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            // Optionally, you can show a success message or feedback
            console.log(`Copied ID: ${id}`);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };


    return (
        <div className='overflow-x-hidden'>
            <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-8" src={euforms} alt="Logo" />
                </div>
            </nav>

            <div className="mt-20 flex flex-col items-center justify-center bg-gray-100">
                <div className="bg-white shadow-md rounded-lg p-12 w-full max-w-4xl">
                    <h1 className="text-4xl font-bold text-green-600 text-center mb-10">
                        Formulário preenchido com sucesso!
                    </h1>
                    <p className="text-gray-600 text-center mb-5 text-lg">
                        Confirmamos que recebemos os dados que inseriu referente à sua compra no <b>valor de {parseFloat(paymentinfo.value).toFixed(2).replace(/\./g, ',')}€</b>. O código do seu registo é o seguinte
                    </p>
                    <div className="flex flex-row items-center justify-center bg-gray-100 p-6 rounded-lg shadow-lg space-x-4 max-w-lg mx-auto">
                        <button
                            className="bg-green-cl hover:bg-green-claro text-white  font-medium rounded-full transition duration-200 ease-in-out shadow-md px-6 py-2 flex items-center justify-center space-x-2"
                            onClick={() => handleCopy(paymentinfo.replyID)}
                            aria-label={`Copy ID ${paymentinfo.replyID}`}
                            title="Copiar Identificador" // Tooltip text
                        >
                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 stroke-current"
                            >
                                <path
                                    d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                ></path>
                                <path
                                    d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                ></path>
                            </svg>
                        </button>
                        <div className="text-center text-gray-800 font-semibold text-lg" title={paymentinfo.replyID}>
                            {paymentinfo.replyID}
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default CashPayment