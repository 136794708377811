import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../context/Context';
import { fetchUsers, deleteUser } from '../../context/forms/FormsAction';
import { URL } from "../../service/Service";
import Header from '../../components/TitleAndSubtitle';
import Breadcrumb from '../../components/Breadcrumb';

const AdminUsers = () => {
    const { state, dispatch } = useContext(Context);
    const url = `${URL}/admin/users/all`;
    const users = Object.values(state.users.data).reverse();
    const navigate = useNavigate();
    const pages = [
        {
            name: 'Dashboard',
            href: `/admin/dashboard`,
            current: false
        },
        {
            name: 'Users',
            href: `/admin/users`,
            current: true
        },
    ];

    //////////////////////SEARCH AND PAGINATION///////////////////////////////

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const isSmallScreen = window.innerHeight <= 600;
        setRowsPerPage(isSmallScreen ? 5 : 10);
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // reset page number when search term changes
    };

    const filteredformsMap = users.filter((item) => {
        return item.userEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.companyID.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const lastRowIndex = currentPage * rowsPerPage;
    const firstRowIndex = lastRowIndex - rowsPerPage;
    const currentRows = filteredformsMap.slice(firstRowIndex, lastRowIndex);
    const totalPages = Math.ceil(filteredformsMap.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];

    // show the first page number
    pageNumbers.push(1);

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i > 1 && i < totalPages) {
            pageNumbers.push(i);
        }
    }

    // show the last page number
    pageNumbers.push(totalPages);

    ////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        fetchUsers(url, {}, dispatch);
    }, [dispatch, url]);

    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleDeleteClick = (userID) => {
        setSelectedUser(userID);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedUser) {
            const url = `${URL}/admin/users/${selectedUser}`;
            const request = {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                },
            };
            deleteUser(url, request, dispatch);
            window.location.reload();
        }
        setShowModal(false);
        setSelectedUser(null);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    return (
        <div className="p-5">
            <Breadcrumb pages={pages} />
            <div className='mb-5'></div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Header title="Utilizadores" />
                <button
                    className="bg-green-cl hover:bg-green-eupago text-white font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/adminregisterpage')}
                >
                    Novo administrador
                </button>
            </div>
            <div className="my-4">
                <input className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="text" placeholder="Procurar por Email ou Nº de Conta" value={searchTerm} onChange={handleSearch} />
            </div>
            <div className="overflow-auto rounded-md">
                <table className="table-auto w-full border">
                    <thead className=''>
                        <tr className="bg-green-cl">
                            <th className="px-4 py-2 text-left text-gray-100">Name</th>
                            <th className="px-4 py-2 text-left text-gray-100">Role</th>
                            <th className="px-4 py-2 text-left text-gray-100">Nº de Conta</th>
                            <th className="px-4 py-2 text-left text-gray-100">Estado Conta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((item) => (
                            <tr key={item.id} className="border-t border-gray-200">
                                <td className="px-4 py-1">{item.userEmail}</td>
                                <td className="px-4 py-1">{item.userRole}</td>
                                <td className="px-4 py-1">{item.companyID}</td>
                                <td className="px-4 py-1">
                                    <button
                                        onClick={() => handleDeleteClick(item.id)}
                                        className={`px-4 py-2 rounded w-20 ${item.deleted ? 'bg-red-500 hover:bg-red-700 text-white' : 'bg-green-500 hover:bg-green-cl text-white'
                                            }`}
                                    >
                                        {item.deleted ? "Inativa" : "Ativa"}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-4">
                {totalPages > 1 && (
                    <nav>
                        <ul className="inline-flex">
                            {pageNumbers.map((number) => (
                                <li key={number} className={currentPage === number ? "px-2 py-1 bg-green-cl text-white rounded-md mx-1" : "px-2 py-1 bg-gray-200 text-gray-700 rounded-md mx-1"}>
                                    <button onClick={() => handlePageChange(number)} className="focus:outline-none">
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg p-4 w-1/3">
                        <h2 className="text-xl mb-4">Confirmar Ação</h2>
                        <p className="mb-4">Tem a certeza que pretende inativar/ativar a conta deste cliente?</p>
                        <div className="flex justify-end">
                            <button onClick={handleCloseModal} className="border-gray-500 border hover:bg-gray-500 hover:text-white text-black px-4 py-2 rounded mr-2">Cancelar</button>
                            <button onClick={handleConfirmDelete} className="bg-green-claro hover:bg-green-cl text-white px-4 py-2 rounded">Confirmar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminUsers;
