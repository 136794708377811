import React from 'react'
import Method from './paymentMethods/Method'

const CheckoutArea = ({method, value, formReplies, fixedValue, itemPurchases}) => {
  return (
    <>
        <Method method={method} value={value} formReplies={formReplies} fixedValue={fixedValue} itemPurchases={itemPurchases}/>
    </>
  )
}

export default CheckoutArea