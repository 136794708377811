import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  ListItemIcon,
  ListItemButton
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/MenuOpen';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import useStyles from '../styles/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import euforms from "../images/euforms.png"
import { URL } from '../service/Service';
import LogoutButtonWhite from './LogoutButtonWhite';
import LogoutButtonBlack from './LogoutButtonBlack';
import { jwtDecode } from 'jwt-decode';


function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const classes = useStyles();
  const [userRole, setUserRole] = useState('');

  React.useEffect(() => {
    const token = sessionStorage.getItem('jwt');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const role = decoded.authorities[0].authority;
        setUserRole(role);
      } catch (error) {
        console.log('Invalid JWT token');
      }
    }
  }, []);
  
  const links = [
    { id: 1, route: 'User', url: userRole === 'USER' || userRole === 'COMPANY' ? '/user' : '/admin/user', icon: PersonIcon },
    // { id: 2, route: 'Settings', url: `/settings`, icon: SettingsIcon },
  ];

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((link) => (
            <ListItem key={link.id} disablePadding>
              <ListItemButton component="a" href={link.url}>
                <ListItemIcon>
                  <link.icon />
                </ListItemIcon>
                <ListItemText primary={link.route} />
              </ListItemButton>
            </ListItem>
        ))}
      </List>
    </Box>
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className='mb-20'>
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar className={classes.toolBar}>
            <Link href={userRole === 'USER' || userRole === 'COMPANY' ? '/forms' : '/admin/dashboard'} underline="none">
              <img src={euforms} className="h-8 w-auto hover:opacity-80" alt=''></img>
            </Link>
            {matches ? (
              <Box>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer('right', true)}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={state['right']}
                  onClose={toggleDrawer('right', false)}
                >
                  {list('right')}
                  <LogoutButtonBlack></LogoutButtonBlack>
                </Drawer>
              </Box>
            ) : <Box
              sx={{
                display: 'flex',
                // justifyContent: 'space-between',
                // flexGrow: '0.06',
                alignItems: 'center',
              }}
            >
              {links.map((link) => (
                <Link href={link.url} underline="none" key={link.id}>
                  <link.icon className="text-white hover:text-green-claro mr-2"></link.icon>
                </Link>
              ))}
              <LogoutButtonWhite></LogoutButtonWhite>
            </Box>}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
};

export default Header;