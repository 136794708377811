import {  Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import Header from "./components/Header";
import FormEdit from "./scenes/formedit";
import FormExample from "./scenes/formexample";
import FormSettings from "./scenes/formsettings";
import { CssBaseline } from "@mui/material";
import ElementEdit from "./scenes/elementedit"
import axios from "axios";
import { URL } from "./service/Service";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from "./scenes/loginpage";
import RegisterPage from "./scenes/registerpage";
import NotFound from "./scenes/notfound";
import ElementPicker from "./components/formElements/ElementPicker";
import Forms from "./scenes/forms";
import FormReader from "./scenes/formreader";
import Replies from "./scenes/replies";
import AdminDashboard from "./scenes/admindashboard";
import AdminForms from "./scenes/adminforms";
import AdminReplies from "./scenes/adminreplies";
import AdminUsers from "./scenes/adminusers";
import AdminRegisterPage from "./scenes/adminregisterpage";
import UserPage from "./scenes/user";
import FormWizard1 from "./scenes/formwizard/index1";
import FormWizard2 from "./scenes/formwizard/index2";
import FormWizard3 from "./scenes/formwizard/index3";
import ElementEditorWizard from "./scenes/elementeditwizard";
import IndexLanding from "./scenes/landingpage/indexlanding";
import AdminUser from "./scenes/adminuser/index"
import ResetPassword from "./scenes/resetpassword";
import ResetPasswordForm from "./scenes/resetpasswordform";
import FormSuccess from "./scenes/formsuccess";

const theme = createTheme({
  palette: {
    primary: {
      main: "#031E1B"
    },
    secondary: {
      main: "#FFFFFF"
    },
    background: {
      default: "#FFFFFF"
    },
  },
  typography: {
    fontFamily: [
    ].join(','),
  },
});


function App() {
  const location = useLocation();
  const token = sessionStorage.getItem('jwt');
  const history = useNavigate();


  useEffect(() => {
    if (!token && location.pathname.startsWith('/clientforms')) {
      // If the user doesn't have a token and is accessing a page starting with /clientforms, allow access
      return;
    }
    else if (location.pathname === '/' && !token) {
      history('/');
    }
    else if (location.pathname === '/register' && !token) {
      history('/register');
    }
    else if (location.pathname === '/resetpassword' && !token) {
      history('/resetpassword');
    }
    else if (location.pathname.startsWith('/resetpassword') && !token) {
      return;
    }
    else if (!token) {
      history('/login');
    } else {
      // Call the validate token endpoint
      axios.get(`${URL}/api/validate`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response);
        }).catch(error => {
          console.log(error);
          history('/login');
        });
    }
  }, [token, history, location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <main className="bg-gray-100 w-full h-full">
          {location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/register' && !location.pathname.startsWith('/clientforms/') && location.pathname !== '/resetpassword' && !location.pathname.startsWith('/resetpassword/')  && <Header/>}
          <Routes>
            <Route path="*" element={<NotFound/>} />
            <Route path="/clientforms/:idform" element={<FormReader />} />
{/*             <Route path="/" element={<LandingPage />} />
 */}            <Route path="/" element={<IndexLanding />} />
            <Route path="/clientforms/success/:method/" element={<FormSuccess/>}/>

            <Route path="/forms/:idform/editform" element={<FormSettings />} />
            <Route path="/forms/:idform/formeditor" element={<FormEdit />} />
            <Route path="/forms/:idform/replies" element={<Replies />} />
            <Route path="/forms/:idform/formelements/:idformelement/edit" element={<ElementEdit />} />
            <Route path="/forms/:idform/formelements/:idformelement/wizard" element={<ElementEditorWizard />} />
            <Route path="/forms" element={<Forms />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/formexample" element={<FormExample />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/resetpassword/:token" element={<ResetPasswordForm />} />
            <Route path="/elementpicker" element={<ElementPicker/>}/>
            <Route path="/admin/forms" element={<AdminForms/>}/>
            <Route path="/admin/forms/:idform/replies" element={<AdminReplies/>}/>
            <Route path="/admin/users" element={<AdminUsers/>}/>
            <Route path="/adminregisterpage" element={<AdminRegisterPage/>}/>
            <Route path="/user" element={<UserPage/>}/>
            <Route path="/forms/:idform/formwizard/1" element={<FormWizard1/>}/>
            <Route path="/forms/:idform/formwizard/2" element={<FormWizard2/>}/>
            <Route path="/forms/:idform/formwizard/3" element={<FormWizard3/>}/>
            <Route path="/admin/forms/:idform/formwizard/1" element={<FormWizard1/>}/>
            <Route path="/admin/forms/:idform/formwizard/2" element={<FormWizard2/>}/>
            <Route path="/admin/forms/:idform/formwizard/3" element={<FormWizard3/>}/>
            <Route path="/admin/user/" element={<AdminUser/>}/>
            <Route path="/admin/forms/:idform/formelements/:idformelement/wizard" element={<ElementEditorWizard />} />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
