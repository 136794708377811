import React from 'react'
import euforms from "../../images/euforms.png"

const Multibanco = ({paymentinfo}) => {

    return (
        <div className='overflow-x-hidden'>
            <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-8" src={euforms} alt="Logo" />
                </div>
            </nav>

            <div className="mt-20 flex flex-col items-center justify-center bg-gray-100">
                <div className="bg-white shadow-md rounded-lg p-12 w-full max-w-4xl">
                    <h1 className="text-4xl font-bold text-green-600 text-center mb-10">
                        Formulário preenchido com sucesso!
                    </h1>
                    <p className="text-gray-600 text-center mb-5 text-lg">
                        O seu formulário foi processado com sucesso. Abaixo pode ver os seu detalhes de pagamento:
                    </p>


                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="py-4 px-6 border text-lg">Método</th>
                                    <th className="py-4 px-6 border text-lg">Referência</th>
                                    <th className="py-4 px-6 border text-lg">Entidade</th>
                                    <th className="py-4 px-6 border text-lg">Valor</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y">
                                <tr className="text-center">
                                    <td className="py-6 px-6 border flex justify-center">
                                        <img
                                            className="w-16"
                                            src="https://eupago.pt/images/tarifario-meios-08.webp"
                                            alt="Multibanco"
                                        />
                                    </td>
                                    <td className="py-6 px-6 border text-lg">{paymentinfo.reference}</td>
                                    <td className="py-6 px-6 border text-lg">{paymentinfo.entity}</td>
                                    <td className="py-6 px-6 border text-lg">
                                        {parseFloat(paymentinfo.value).toFixed(2).replace(/\./g, ',')} €
                                    </td>                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Multibanco