import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import Context from '../../context/Context';
import { fetchForm, editForm } from '../../context/forms/FormsAction';
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL, URL_FRONT, makeHttpRequest } from "../../service/Service";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { jwtDecode } from 'jwt-decode';

const FormWizard3 = () => {
    const { state, dispatch } = useContext(Context);
    const { form } = state;
    const { idform } = useParams();
    const [fileName, setFileName] = useState("");
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [URI, setURI] = useState();
    const [file, setFile] = useState();
    const [showModal, setShowModal] = useState(false);
    const token = sessionStorage.getItem('jwt');
    const userRole = jwtDecode(token).authorities[0].authority;
    const location = useLocation();
    const navigate = useNavigate();

    const handleFinalizar = () => {
        setShowModal(true);
        setTimeout(() => {
            if (userRole === 'ADMIN') {
                window.location.href = `${URL_FRONT}/admin/forms/`;
            } else {
                window.location.href = `${URL_FRONT}/forms/`;
            }
        }, 2000);
    };

    useEffect(() => {
        const url = `${URL}/api/forms/${idform}`;
        fetchForm(url, {}, dispatch);
    }, [dispatch, idform]);

    
    useEffect(() => {
        const isAdminPath = location.pathname.startsWith('/admin/forms/');
        const isFormWizard1Path = location.pathname.endsWith('/formwizard/3');
        
        if (isAdminPath && isFormWizard1Path && userRole !== 'ADMIN') {
            navigate('/login'); // Redirect to login screen if not ADMIN and accessing admin path
        }
    }, [location.pathname, navigate, userRole]);

    const uploadFileToForm = useCallback((file) => {
        const formData = new FormData();
        formData.append("file", file);
        const url = `${URL}/uploadFile/${form.data.id}`;
        const request = {
            method: "POST",
            body: formData,
        };

        makeHttpRequest(
            url,
            request,
            (response) => {
                console.log(response);
                setURI(response.fileDownloadUri);
                window.location.reload(); // Add this statement
            },
            (error) => {
                console.error(error);
            }
        );
    }, [form.data.id]);

    const removeLogo = useCallback(() => {
        const url = `${URL}/api/forms/${idform}`;
        const request = {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                logo: "removed",
            }),
        };
        editForm(url, request, dispatch);
        window.location.reload();
    }, [dispatch, idform]);


    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
        setFileName(acceptedFiles[0].name);
        setShowCancelButton(true);
    }, []);

    const handleCancel = useCallback(() => {
        setFile(null);
        setFileName("");
        setShowCancelButton(false);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*",
    });

    const handleButtonClickBack = () => {
        if (userRole === 'ADMIN') {
            navigate(`/admin/forms/${idform}/formwizard/2`);
        } else {
            navigate(`/forms/${idform}/formwizard/2`);
        }
    };


    return (
        <div className='p-5'>
            <div>
                <div className="flex justify-between items-center mb-10">
                    <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                        onClick={handleButtonClickBack}
                    >Anterior</button>
                    <div className='w-full ml-10 mr-10 mt-8'>
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div className="h-2 w-full rounded-full bg-green-cl"></div>
                        </div>

                        <ol className="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
                            <li className="flex items-center justify-start text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>

                                <span className="hidden sm:inline"> Editor </span>

                            </li>

                            <li className="flex items-center justify-center text-black">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span className="hidden sm:inline"> Definições </span>


                            </li>

                            <li className="flex items-center justify-end text-black font-bold">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                                </svg>

                                <span className="hidden sm:inline"> Upload Imagem </span>
                            </li>
                        </ol>
                    </div>
                    <button className="mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white text-xs py-2 px-4 rounded"
                        onClick={() => handleFinalizar()
                        }
                    >Finalizar</button>
                </div>
                {showModal && (
                    <div className="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
                        <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
                            <button className="absolute top-0 right-0 px-2 py-1" type='button'>x</button>
                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                <div className="text-green-500">
                                    <svg className="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </div>
                                <div className="text-sm font-medium ml-3">Sucesso:</div>
                            </div>
                            <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">O seu formulário foi guardado com sucesso</div>
                        </div>
                    </div>
                )}
            </div>
            {form.data.logo !== "" && form.data.logo !== "removed" && (
                <div className="relative mb-4">
                    <div className="h-56 bg-white rounded border shadow flex items-center justify-center p-6">
                        <img className="max-w-full max-h-full" src={form.data.logo} alt='' />
                        <button
                            type="button"
                            onClick={() => removeLogo()}
                            className="absolute top-0 left-0 bottom-0 shadow bg-red-500 hover:bg-opacity-80 text-white w-6 rounded"
                        >
                            <DeleteForeverIcon />
                        </button>
                    </div>
                </div>
            )}
            <div {...getRootProps()} className="cursor-pointer mb-4 p-10 border border-dashed items-center justify-center text-center bg-gray-200 border-gray-400 rounded w-full">
                <center>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p className="text-gray-700 w-fit font-medium">Largue aqui a sua imagem</p>
                    ) : (
                        fileName ? null : <p className="items-center text-center justify-center text-gray-700 w-fit font-medium">
                            Arraste uma imagem para aqui
                        </p>
                    )}
                    {fileName ? <p className="mt-2 items-center w-fit text-gray-700 font-medium">Ficheiro: {fileName}</p> : null}
                </center>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-end gap-2">
                <button type="button" onClick={() => uploadFileToForm(file)} className="w-full sm:w-auto mt-2 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                    Enviar
                </button>
                {showCancelButton ? (
                    <button type="button" onClick={handleCancel} className="w-full sm:w-auto mt-2 sm:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                        Cancelar
                    </button>
                ) : null}
            </div>
        </div>)
}

export default FormWizard3